require('../font/font/iconfont.css');
require('../font/zhongyi/iconfont.css');

require('../css/tailwind_common.css');
require('../css/style.css');

require('../ext/swiper1105/swiper-bundle.css');


//----- common 1 ： lazy
// const {Lazy} = require('../js/lazy.js');
// // console.log(Lazy);
// let lazy = new Lazy;
// lazy.exc( document.querySelectorAll("[data-_src]") );
// lazy.exc();//所有具有data-_src的图片都执行lazy


//----- common 2 ： 统计
var _hmt = _hmt || [];
;(function() {
  // console.log(process.env.NODE_ENV);
  // return;
  if(process.env.NODE_ENV!='production') return;

  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?2b77ee7641d100ff4805e1fb33ca2ab7";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();


window.msgSbumit = function(submitBtn){

  // 1. 取回表单对象
  var myform = submitBtn.parentNode;
  // console.log(submitBtn.parentNode);

  // 2. 格式化表单数据
  myform['phone'].value = myform['phone'].value-0;
  myform['msg'].value = myform['msg'].value.trim();

  // 3. 表单数据判断 
  if(myform['phone'].value<=13000000000 || myform['phone'].value>=19999999999){alert('手机号码格式错误！');return;}
  if(myform['msg'].value.length<4){alert('留言内容不能少于4位！');return;}
  
  // 4. 加入其它必要数据（加入当前Url）
  myform['url'].value = window.location.pathname+window.location.search;

  console.log(myform['phone'].value, myform['msg'].value, myform['url'].value);
  // return;

  // 5. 提交表单
  submitBtn.parentNode.submit();
  submitBtn.parentNode.reset();



  /* 
  fd.set('phone', fd.get("phone")-0 );
  fd.set('msg', fd.get("msg").trim() );
  
  // 3. 表单数据判断 
  if(fd.get("phone")<=13000000000 || fd.get("phone")>=19999999999){alert('手机号码格式错误！');return;}
  if(fd.get("msg").length<4){alert('留言内容不能少于4位！');return;}

  // 4. 加入其它必要数据（加入当前Url）
  fd.append('url', window.location.pathname+window.location.search)

  // for (var value of fd.values()) {
  //   console.log(value);
  // }

  // 5. 提交表单
  submitBtn.parentNode.submit();
  submitBtn.parentNode.reset();
  */

}








/* 


window.msgSbumit = function(submitBtn){

  // 1. 取回表单对象
  var fd = new FormData(submitBtn.parentNode);
  console.log( submitBtn.parentNode);

  // 2. 格式化表单数据
  fd.set('phone', fd.get("phone")-0 );
  fd.set('msg', fd.get("msg").trim() );
  
  // 3. 表单数据判断 
  if(fd.get("phone")<=13000000000 || fd.get("phone")>=19999999999){alert('手机号码格式错误！');return;}
  if(fd.get("msg").length<4){alert('留言内容不能少于4位！');return;}

  // 4. 加入其它必要数据（加入当前Url）
  fd.append('url', window.location.pathname+window.location.search)

  // for (var value of fd.values()) {
  //   console.log(value);
  // }

  // 5. 提交表单
  submitBtn.parentNode.submit();
  submitBtn.parentNode.reset();

}

*/



// const { createApp, ref } = vue;

// var x = createApp({

//   setup() {

 

//   }

// }).mount('#leavemsg');


// 在线客服不要放在这里2024-08-30 10:43:17
// {
//   var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
//   (function(){
//     return;
//   var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
//   s1.async=true;
//   s1.src='https://embed.tawk.to/66b57c3d0cca4f8a7a73a628/1i4qeql3h';
//   s1.charset='UTF-8';
//   s1.setAttribute('crossorigin','*');
//   s0.parentNode.insertBefore(s1,s0);
//   })();
// }

